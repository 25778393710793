'use client';

import { ReactNode } from 'react';
import { createInstance, Resources } from 'i18next';
import { I18nextProvider } from 'react-i18next';

import initTranslations, { I18nNamespaces } from '_app/i18n';

type TranslationsProviderProps = {
  children: ReactNode;
  locale: string;
  namespaces: I18nNamespaces;
  resources: Partial<Resources>;
};

export const TranslationsProvider = ({
  children,
  locale,
  namespaces,
  resources,
}: TranslationsProviderProps) => {
  const i18n = createInstance();
  initTranslations(locale, namespaces, i18n, resources);

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};
