'use client';

import dynamic from 'next/dynamic';

import { AuthSigninViewProps } from './AuthSigninView';

const AuthSigninView = dynamic(
  () => import('./AuthSigninView').then((mod) => mod.AuthSigninView),
  { ssr: false }
);

// This is a workaround to force client-side rendering of the AuthSigninView
export const AuthSigninBaseView = ({
  providers,
  countryCode,
}: AuthSigninViewProps) => (
  <AuthSigninView providers={providers} countryCode={countryCode} />
);
